
import { getSuggestions } from '../store/actions'
import { ISuggestion } from '../store/state'
import Vue from 'vue'

interface SearchFormData {
  query: string
	suggestions: ISuggestion[]
}

interface SearchFormComputed {
  showSearch: boolean
  searchPageUri: string
  isMobile: boolean
  suggestions: ISuggestion[]
}

interface SearchFormMethods {
  clearQuery(event: Event): void
  getSuggestions(): void
	nextSuggestion(e: Event, index: number): void
	previousSuggestion(e: Event, index: number): void
	closeSuggestions(focusInput: boolean): void
	closeSuggestionsHandler(e: Event): void
}

export default Vue.extend<{}, SearchFormMethods, SearchFormComputed, SearchFormData>({
	name: 'SearchForm',
  data() {
    return {
      query: ''
    }
  },
  computed: {
    showSearch() {
      return this.$store.state.showSearch;
    },
    searchPageUri() {
      return this.$store.state.searchPageUri;
    },
    isMobile() {
      return this.$store.state.isMobile;
    },
    suggestions() {
			return this.$store.state.suggestions;
		}
  },
  methods: {
    clearQuery() {
      this.$data.query = '';
    },
    getSuggestions() {
				if(this.query.length > 1) {
						this.$store.dispatch(getSuggestions(this.query))
				}
		},
		nextSuggestion(e, index) {
			e.preventDefault();
			if((index + 1) < this.suggestions.length) {
					const suggestions = this.$refs.suggestion as Array<HTMLAnchorElement>;
					suggestions[index + 1].focus();
			}
		},
		previousSuggestion(e, index) {
				e.preventDefault();
				if(index > 0) {
						const suggestions = this.$refs.suggestion as Array<HTMLAnchorElement>;
						suggestions[index - 1].focus();
				} else {
						const input = this.$refs.input as HTMLInputElement;
						input.focus();
				}
		},
		closeSuggestions(focusInput) {
			this.$store.commit({
        type: 'removeSuggestions',
        payload: undefined
      })

			if(focusInput) {
				const input = this.$refs.input as HTMLInputElement;
				input.focus();
			}
		},
		closeSuggestionsHandler(e) {
			const suggestionsRef = this.$refs.suggestions;
			const inputRef = this.$refs.input;
			const target = e.target;
			
			if(suggestionsRef !== target 
				&& !(suggestionsRef as HTMLElement).contains(target as HTMLElement) 
				&& inputRef !== target 
				&& !(inputRef as HTMLElement).contains(target as HTMLElement)) {
					this.closeSuggestions(false)
			}
		},
  },
  mounted() {
		window.addEventListener('click', this.closeSuggestionsHandler);
	},
	beforeDestroy() {
		window.removeEventListener('click', this.closeSuggestionsHandler);
	},
})
