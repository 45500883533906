import { IMenuItem, IState } from "./state";
import { ActionTree, Store, ActionContext } from 'vuex';
import * as api from '../api';

type PayloadType = 'initApp' | 'setState' | 'getSuggestions' | 'removeSuggestions' | 'search' | 'toggleMenu' | 'getChildren' | 'expandMenuItem'
export type ActionPayload<ActionData> = {
	type: PayloadType,
	payload: ActionData
}

export interface IGetChildrenPayload {
	id: string
	level: number
}

type ActionHandler<ActionData> = (this: Store<IState>, injectee: ActionContext<IState, IState>, payload: ActionPayload<ActionData>) => any;
type ActionCreator<ActionData> = (data: ActionData) => ActionPayload<ActionData>;


// Action creators
export const initApp: ActionCreator<IState> = (state) => ({
	type: 'initApp',
	payload: state
})

export const getSuggestions: ActionCreator<string> = query => ({
	type: 'getSuggestions',
	payload: query
})

export const removeSuggestions: ActionCreator<undefined> = () => ({
	type: 'removeSuggestions',
	payload: undefined
})

export const search: ActionCreator<string> = (query) => ({
	type: 'search',
	payload: query
})

export const toggleMenu: ActionCreator<boolean> = payload => ({
	type: 'toggleMenu',
	payload: payload
})

export const getChildren: ActionCreator<IGetChildrenPayload> = payload => ({
	type: 'getChildren',
	payload: payload
})

export const expandMenuItem: ActionCreator<IMenuItem> = menuItem => ({
	type: 'expandMenuItem',
	payload: menuItem
})

// Action handlers
const initAppAction: ActionHandler<IState> = ({ commit }, { payload }) => {
	commit({
		type: 'setState',
		payload
	})
}

const getSuggestionsAction: ActionHandler<string> = ({ commit, state }, { payload }) => {
	const getSuggestionsURL = state.endpoints.getSuggestions;
	api.getSuggestions(getSuggestionsURL, payload)
		.then(res => {
			commit({
				type: 'setSuggestions',
				payload: res
			})
		})
}

const toggleMenuAction: ActionHandler<boolean> = ({ commit, state }, { payload }) => {
	api.getMenu(state.endpoints.getMenu)
		.then(res => {
			commit({
				type: 'setMenu',
				payload: res
			})
			commit({
				type: 'showMenu',
				payload: payload
			})
		})
}

const getChildrenAction: ActionHandler<IMenuItem> = ({ commit, state }, { payload }) => {
	api.getMenuItems(state.endpoints.getChildren, {id: payload.id, level: payload.level})
		.then(res => {
			commit({
				type: 'addMenuItems',
				payload: {menuItem: payload, items: res}
			})
		})
}

const expandMenuItemAction: ActionHandler<IMenuItem> = ({ commit, dispatch, state }, { payload }) => {
	commit({
		type: 'expandMenuItem',
		payload: payload
	})

	if(payload.children.length < 1) {
		dispatch(getChildren(payload))
	}
}
const actions: ActionTree<IState, IState> = {
	initApp: initAppAction,
	getSuggestions: getSuggestionsAction,
	toggleMenu: toggleMenuAction,
	getChildren: getChildrenAction,
	expandMenuItem: expandMenuItemAction
}

export default actions;
