
import Vue from 'vue'

interface MenuButtonMethods {
  toggleMenu(): void
}

interface MenuButtonComputed {
  showMenu: boolean
}

export default Vue.extend<{}, MenuButtonMethods, MenuButtonComputed, {}>({
	name: 'MenuButton',
  computed: {
    showMenu() {
      return this.$store.state.showMenu
    },
  },
  methods: {
    toggleMenu() {
      const header = document.querySelector('header');
      const height = header ? window.innerHeight - header.clientHeight : 0;
      this.$store.commit({
        type: 'setMenuHeight',
        payload: height
      })

      this.$store.dispatch({
        type: 'toggleMenu',
        payload: !this.showMenu
      })

      setTimeout(() => {
        const html = document.documentElement;
        if(!this.showMenu) {
          html.removeAttribute('data-menu-open');
        } else {
          html.setAttribute('data-menu-open', '');
        }
      }, 250)
    }
  },
  components: {
  }
})
