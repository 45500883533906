
import Vue from 'vue'
import { ITranslationLink } from '../store/state'

interface LogoComputed {
  translationLink: ITranslationLink
}

export default Vue.extend<{}, {}, LogoComputed, {}>({
	name: 'Translation',
  computed: {
    translationLink() {
      return this.$store.state.translationLink;
    }
  },
})
