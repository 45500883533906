
import Vue from 'vue'
import { IMenuItem } from '../store/state';
import MenuItem from './MenuItem.vue';
import Translation from './Translation.vue';

interface MenuMethods {
  closeMenu(): void
  deactivateCurrentItem(): void
}

interface MenuComputed {
  menu: IMenuItem[]
  currentLevel: number
  showMenu: boolean
  isMobile: boolean
  menuHeight: number
}

interface MenuProps {
}

export default Vue.extend<{}, MenuMethods, MenuComputed, MenuProps>({
	name: 'Menu',
  computed: {
    menu(){
      return this.$store.state.menu
    },
    currentLevel(){
      return this.$store.getters.menuBreadcrumbs.length
    },
    showMenu(){
      return this.$store.state.showMenu
    },
    isMobile(){
      return this.$store.state.isMobile
    },
    menuHeight(){
      return this.$store.state.menuHeight
    },
  },
  methods: {
    closeMenu() {
      this.$store.commit({
        type: 'showMenu',
        payload: false
      })

      const html = document.documentElement;
      html.removeAttribute('data-menu-open');
    },
    deactivateCurrentItem() {
      this.$store.commit({
        type: 'setTransitionName',
        payload: 'backward'
      })
      this.$store.commit({
        type: 'deactivateCurrentItem'
      })
    }
  },
  mounted() {
    !this.isMobile && window.addEventListener('click', (e) => {
      const appRef = this.$refs.menu as HTMLElement;
      const target = e.target as HTMLElement;
      if((appRef?.contains(target)) || target.dataset?.ref === 'menu-button' || target.hasAttribute('data-ignore-close')) return;
      if(this.showMenu) this.closeMenu();
    })
  },
  components: {
    MenuItem,
    Translation
  }
})
