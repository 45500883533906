
import Vue from 'vue'

interface SearchButtonComputed {
  showSearch: boolean
  isMobile: boolean
}

interface SearchButtonMethods {
  toggleSearch(): void
}

export default Vue.extend<{}, SearchButtonMethods, SearchButtonComputed, {}>({
	name: 'SearchButton',
  computed: {
    showSearch() {
      return this.$store.state.showSearch
    },
    isMobile() {
      return this.$store.state.isMobile
    }
  },
  methods: {
    toggleSearch() {
      this.$store.commit({
          type: 'toggleSearch',
          payload: !this.showSearch
      })
    }
  },
})
