import { IGetChildrenPayload } from '@/store/actions'
import { IMenuItem } from '@/store/state'
import axios from 'axios'
const qs = require('query-string')

const headers = {
	'X-Requested-With': 'XMLHttpRequest',
	'Accept': 'application/json',
}

const stringifyParams = (paramsObject: {}) => {
    let params = [];
    for (const [key, value] of Object.entries(paramsObject)) {
        params.push(`${key}=${value}`)
    }
    return params.join('&')
}

export const getMenuItems = (searchURL: string, params: IGetChildrenPayload): Promise<IMenuItem[]> => {
    let url = `${searchURL}?${stringifyParams({
        id: params.id,
        level: params.level
    })}`
    return axios.get(url, { headers })
        .then((response:any) => {
            return response.data
        })
        .catch((searchError:any) => {
            console.error({
                searchError
            })
            return {} as IMenuItem[]
        })
}

export const getMenu = (searchURL: string): Promise<IMenuItem[]> => {
    return axios.get(searchURL, {headers: headers})
        .then((response:any) => {
            return response.data
        })
        .catch((searchError:any) => {
            console.error({
                searchError
            })
            return {} as IMenuItem[]
        })
}

export const getSuggestions = (suggestionsURL: string, queryParam: string): Promise<string> => {
    let url = `${suggestionsURL}?${stringifyParams({
      query: queryParam
    })}`
    return axios.get(url, { headers })
      .then((response:any) => {
          return response.data
      })
      .catch((searchError:any) => {
          console.error({
              searchError
          })
          return {} as string[]
      })
  }