
import Vue from 'vue'
import { IMenuItem } from '../store/state'
import { expandMenuItem } from '../store/actions'

interface MenuItemProps {
  item: IMenuItem
}

interface MenuItemComputed {
  currentLevel: number
  isMobile: boolean
  transitionName: string
}

interface MenuItemMethods {
  expandMenuItem(item: IMenuItem): void
  checkExpanded(): boolean
}

export default Vue.extend<{}, MenuItemMethods, MenuItemComputed, MenuItemProps>({
  name: 'MenuItem',
  props: {
    item: {},
  },
  computed: {
    currentLevel(){
      return this.$store.getters.menuBreadcrumbs.length
    },
    isMobile(){
      return this.$store.state.isMobile
    },
    transitionName(){
      console.log(this.$store.state.transitionName)
      return this.$store.state.transitionName
    }
  },
  methods: {
    expandMenuItem(item) {
      this.$store.commit({
        type: 'setTransitionName',
        payload: 'forward'
      })
      this.$store.dispatch(expandMenuItem(item))
    },
    checkExpanded() {
      if(this.item.isExpanded) return this.currentLevel <= this.item.level
      else if(!this.item.isExpanded) return this.currentLevel < this.item.level
      else return false
    }
  }
})
