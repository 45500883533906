import { Getter } from "vuex";
import { IMenuItem, IState } from './state';
const MAX_ITERATIONS = 1000;

const checkExpanded = (children?: IMenuItem[], breadcrumbs?: IMenuItem[], iteration?: number) => {
		if(iteration && iteration > MAX_ITERATIONS || !children) return
		const crumbs = breadcrumbs ? breadcrumbs : []
		let i = iteration ? iteration : 0;

		children && children.some((child: IMenuItem) => {
				if (child.isExpanded) {
						crumbs.push(child)
						if (child.hasChildren) checkExpanded(child.children, crumbs, ++i)
						return true;
				}
		})
		return crumbs
}

const menuBreadcrumbs: Getter<IState, IState> = (state, getters) => {
	const breadcrumbs = checkExpanded(state.menu);
	if(breadcrumbs) state.menuBreadcrumbs = breadcrumbs
	return state.menuBreadcrumbs;
}

export default {
	menuBreadcrumbs
}
