
import Vue from 'vue'
import { ILogotype } from '../store/state'

interface LogoComputed {
	logo: ILogotype
  startPage: Object
}

export default Vue.extend<{}, {}, LogoComputed, {}>({
	name: 'Logotype',
  computed: {
    startPage() {
      return this.$store.state.startPage;
    },
    logo() {
      return this.$store.state.logotype;
    },
  },
})
