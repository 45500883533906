
import Vue from 'vue'
import Logotype from './components/Logotype.vue';
import MenuButton from './components/MenuButton.vue';
import Menu from './components/Menu.vue';
import SearchForm from './components/SearchForm.vue';
import { FocusTrap } from 'focus-trap-vue'
import SearchButton from './components/SearchButton.vue';
Vue.component('FocusTrap', FocusTrap);

interface AppComputed {
  showMenu: boolean
  isMobile: boolean
}

interface AppData {
}

interface AppMethods {
  closeMenu(): void
}

export default Vue.extend<AppData, AppMethods, AppComputed, {}>({
  name: 'App',
  computed: {
    showMenu() {
			return this.$store.state.showMenu
		},
    isMobile() {
			return this.$store.state.isMobile
    }
  },
  methods: {
    closeMenu() {
      this.$store.commit({
        type: 'showMenu',
        payload: false
      })

      const html = document.documentElement;
      html.removeAttribute('data-menu-open');
    }
  },
  components: {
    Logotype,
    SearchForm,
    Menu,
    MenuButton,
    SearchButton
  },
  created() {
		this.$store.commit({
      type: 'checkMobile', 
      payload: window.innerWidth
    })
  
    document.addEventListener('DOMContentLoaded', () => {
      const header = document.querySelector('header');
      let height = 0;
      setTimeout(() => {
         height = header ? window.innerHeight - header.clientHeight : 0;
      },250)

      addEventListener('resize', () => {
        height = header ? window.innerHeight - header.clientHeight : 0;

        const width = Math.max(
          document.body.scrollWidth,
          document.documentElement.scrollWidth,
          document.body.offsetWidth,
          document.documentElement.offsetWidth,
          document.documentElement.clientWidth,
          window.innerWidth
          );
        
        this.$store.commit({
          type: 'checkMobile', 
          payload: width
        });
        
        this.$store.commit({
          type: 'setMenuHeight',
          payload: height
        })
      })    
		})
	},
})
